import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: '100vh',
    overflow: 'auto',
  },
});

const Content = props => {
  const { children, classes } = props;
  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Grid container justify="center">
        <Grid item xs={12} sm={8} md={4}>
          {children}
        </Grid>
      </Grid>
    </main>
  );
};

export default withStyles(styles)(Content);
