import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { calculateMeter, normalizeTemp } from '../utils';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
});

const defaultState = {
  current: '',
  display: '',
  temp: '',
  sg: '',
  weight: '',
  cubicFt: '',
  gallons: '',
  error: '',
  adjusted: '',
};

class WaterForm extends React.Component {
  state = {
    ...defaultState,
  };

  updateState = (id, value) => {
    this.setState(state => {
      const newState = calculateMeter({ ...state, [id]: value });
      return { ...newState, [id]: value };
    });
  };

  onInputChange = event => {
    const value = parseFloat(event.target.value) || '';
    const id = event.target.id;
    this.updateState(id, value);
  };

  onTempBlur = event => {
    if (!event.target.value) {
      return;
    }

    const value = parseFloat(event.target.value) || '';
    const id = event.target.id;
    this.updateState(id, normalizeTemp(value));
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Paper className={classes.root} elevation={1}>
          <Typography component="h1" variant="h6" align="center">
            Meter Calibration Point #{1}
          </Typography>
          <form>
            <TextField
              type="number"
              fullWidth
              margin="dense"
              label="Current Calibration Settings"
              id="current"
              onChange={this.onInputChange}
              value={this.state.current}
            />
            <TextField
              type="number"
              fullWidth
              margin="dense"
              label="Displayed Meter Reading"
              id="display"
              value={this.state.display}
              onChange={this.onInputChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">gal</InputAdornment>,
              }}
            />
            <TextField
              type="number"
              fullWidth
              margin="dense"
              label="Water Temperature"
              id="temp"
              onBlur={this.onTempBlur}
              onChange={this.onInputChange}
              value={this.state.temp}
              InputProps={{
                endAdornment: <InputAdornment position="end">&deg;F</InputAdornment>,
              }}
            />
            <TextField
              type="number"
              fullWidth
              margin="dense"
              label="Specific Gravity"
              id="sg"
              onChange={this.onInputChange}
              value={this.state.sg}
              InputProps={{
                endAdornment: <InputAdornment position="end">lb/ft&sup3;</InputAdornment>,
                readOnly: true,
              }}
            />
            <TextField
              type="number"
              fullWidth
              margin="dense"
              label="Weight"
              id="weight"
              onChange={this.onInputChange}
              value={this.state.weight}
              InputProps={{
                endAdornment: <InputAdornment position="end">lb</InputAdornment>,
              }}
            />
            <TextField
              type="number"
              fullWidth
              margin="dense"
              label="Volume Cubic Feet"
              id="cubicFt"
              onChange={this.onInputChange}
              value={this.state.cubicFt}
              InputProps={{
                endAdornment: <InputAdornment position="end">ft&sup3;</InputAdornment>,
                readOnly: true,
              }}
            />
            <TextField
              type="number"
              fullWidth
              margin="dense"
              label="Volume Gallons"
              id="gallons"
              onChange={this.onInputChange}
              value={this.state.gallons}
              InputProps={{
                endAdornment: <InputAdornment position="end">gal</InputAdornment>,
                readOnly: true,
              }}
            />
            <TextField
              type="number"
              fullWidth
              margin="dense"
              label="Percent Error"
              id="error"
              onChange={this.onInputChange}
              value={this.state.error}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                readOnly: true,
              }}
            />
            <TextField
              type="number"
              fullWidth
              margin="dense"
              label="Adjusted Settings"
              id="adjusted"
              onChange={this.onInputChange}
              value={this.state.adjusted}
              InputProps={{
                readOnly: true,
              }}
            />
            <div className={classes.actions}>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() =>
                  this.setState({
                    ...defaultState,
                  })
                }
              >
                Reset
              </Button>
            </div>
          </form>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(WaterForm);
