export function calculateMeter(state) {
  const sg = getSpecificGravity(state.temp);
  const cubicFt = getWaterVolumeFt(state.weight, sg);
  const gallons = convertToGallons(cubicFt);
  const error = percentError(state.display, gallons);
  const adjusted = adjustMeter(state.current, state.display, gallons);

  const newState = {
    sg,
    cubicFt: roundToDecimal(cubicFt, 2),
    gallons: roundToDecimal(gallons, 2),
    error: roundToDecimal(error, 2),
    adjusted: roundToDecimal(adjusted),
  };

  return newState;
}

function getSpecificGravity(temp) {
  if (!temp) {
    return '';
  }
  const i = (temp - 32) / 2;
  return lookup[i];
}

function getWaterVolumeFt(weight, sg) {
  if (!weight || !sg) {
    return '';
  }
  return weight / sg;
}

function convertToGallons(cubicFt) {
  if (!cubicFt) {
    return '';
  }
  return cubicFt * 7.4805;
}

function percentError(display, gallons) {
  console.log(display, gallons);
  if (!display || !gallons) {
    return '';
  }
  return ((gallons - display) / display) * 100;
}

function adjustMeter(current, display, gallons) {
  if (!current || !display || !gallons) {
    return '';
  }
  return (gallons / display) * current;
}

export function normalizeTemp(temp) {
  if (!temp) {
    return '';
  }
  const rounded = Math.round(parseFloat(temp) / 2) * 2;
  if (rounded < 32) {
    return 32;
  } else if (rounded > 170) {
    return 170;
  }
  return rounded;
}

export function roundToDecimal(number, n = 0) {
  if (!number) {
    return '';
  }
  const j = Math.pow(10, n);
  return Number((Math.round(number * j) / j).toFixed(n));
}

// Lookup table (index 0 = 32 deg F)
const lookup = [
  62.4181,
  62.4222,
  62.4248,
  62.4262,
  62.4263,
  62.4252,
  62.4229,
  62.4195,
  62.415,
  62.4094,
  62.4024,
  62.3952,
  62.3866,
  62.3771,
  62.3666,
  62.3552,
  62.343,
  62.3299,
  62.316,
  62.3013,
  62.2858,
  62.2695,
  62.2525,
  62.2347,
  62.2162,
  62.1969,
  62.177,
  62.1564,
  62.1351,
  62.1132,
  62.0906,
  62.0674,
  62.0436,
  62.0191,
  61.9941,
  61.9684,
  61.9422,
  61.9154,
  61.888,
  61.86,
  61.8316,
  61.8026,
  61.7731,
  61.743,
  61.7124,
  61.6813,
  61.6497,
  61.6176,
  61.5849,
  61.5519,
  61.5183,
  61.4842,
  61.4497,
  61.4147,
  61.3793,
  61.3434,
  61.307,
  61.2702,
  61.233,
  61.1953,
  61.1572,
  61.1187,
  61.0797,
  61.0403,
  61.0005,
  60.9603,
  60.9197,
  60.8787,
  60.8373,
  60.7954,
];
