import { createMuiTheme } from '@material-ui/core/styles';
export default createMuiTheme({
  palette: {
    primary: {
      main: '#2b2d42',
    },
    secondary: {
      main: '#8d99ae',
    },
    background: {
      main: '#edf2f4',
    },
    error: {
      main: '#ed1c24',
    },
  },
  typography: {
    useNextVariants: true,
  },
});
