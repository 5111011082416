import React from 'react';
import Navbar from './Navbar';
import Content from './Content';
import WaterForm from './WaterForm';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '../styles/theme';

const App = () => {
  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar />
        <Content>
          <WaterForm />
        </Content>
      </MuiThemeProvider>
    </React.Fragment>
  );
};

export default App;
